import InternetPackage from './InternetPackage';
import amc from '../../images/checkout/amc_logo.png';
import cinemax from './../../images/packages/cinemax.svg';
import cinemax1 from '../../images/checkout/cinemax.png';
import cinemax2 from '../../images/checkout/cinemax2.png';
import diema from './../../images/packages/diema-extra.svg';
import diema1 from '../../images/checkout/diema1.png';
import diema2 from '../../images/checkout/diema2.png';
import hbo from './../../images/packages/hbo-logo.svg';
import hbo2 from '../../images/checkout/hbo2.png';
import hbo3 from '../../images/checkout/hbo3.png';
import max from './../../images/help/max.svg';
import maxsport from './../../images/packages/maxsport-plus.svg';
import sedemosmi from '../../images/checkout/sedemosmi.png';
import sevenEight from './../../images/packages/seven-eight.svg';
import trace from '../../images/checkout/trace.png';
import tvAdoQ from './../../images/packages/tv-a-do-q.svg';
import tvFilmov from './../../images/packages/tv-filmov.svg';
import tvNewBeginning from './../../images/packages/tv-new-beginning.svg';
import tvPurvaStupkaIcon from './../../images/packages/tv-purva-stupka.svg';
import tvSporten from './../../images/packages/tv-sporten.svg';
import tvZlaten from './../../images/packages/tv-zlaten-standard.svg';

class TvExtra {
	constructor(name, description, icons, price) {
		this.name = name;
		this.description = description;
		this.icons = icons.slice();
		this.price = price;
	}
}

class TvPackage extends InternetPackage {
	extras = [
		{
			extra: new TvExtra(
				'Cinemax & AMC',
				'С включените канали Cinemax & AMC във всички пакети на НетСърф, ще гледате холивудски продукции, както и пълни се екшън сериали.',
				[amc, cinemax1, cinemax2],
				0
			),
			isAdded: true,
			isIncluded: true
		},
		{
			extra: new TvExtra(
				'HBO',
				'Ти си филмов маниак? Обичаш завладяващите филми и сериали? Потопи се в океана от над 200 сериала, 800 филма и детски предложения, като добавиш HBO към своя пакет. Получаваш и достъп до HBOGO, за да гледаш в движение най-добро от света на филмите и сериалите.',
				[hbo, hbo2, hbo3],
				10.4
			),
			isAdded: false,
			isIncluded: false
		},
		{
			extra: new TvExtra(
				'Diema Xtra',
				'Добави Diema Xtra към пакета си и гледай мачове от Шампионска Лига, Лига Европа, английски, френски, немски и български футбол,  както и Формула 1, НБА, Волейбол България, Бокс и много други спортове.',
				[diema1, diema2, trace],
				8.99
			),
			isAdded: false,
			isIncluded: false
		},
		{
			extra: new TvExtra(
				'7/8',
				'Ти си алтернативен? Искаш да гледаш съдържание отвъд наложените програмни модели? Разчупи представата си за телевизия с аналитични предавания и набор от забавни и музикални програми с телевизия 7/8.',
				[sedemosmi],
				0.78
			),
			isAdded: false,
			isIncluded: false
		}
	];

	constructor(
		id,
		size,
		speed,
		price,
		features,
		title,
		hdFetcherInfo,
		tvCount,
		ont,
		ontcount
	) {
		super(id, size, speed, price, features);
		this.channelId = features.channelId;
		this.title = title;
		this.tvChannels = tvCount;
		this.tvChannelsCount = parseInt(
			title
				.trim()
				.split(' ')[0]
				.trim()
		);
		this.hdChannels = features.hdChannels;
		this.hdFetcher = tvCount;
		this.hdFetcherInfo = features.tooltipStbAurora;
		this.ontFetcher = ont;
		this.ontFetcherValue = ontcount;
		this.ontFetcherInfo = features.tooltipOnt;
		this.isOnlyInternet = false;

		this.internalTitle = features.internalname || '';
		this.includedChannelImages = this.getIncludedChannelImage(
			this.internalTitle.toLowerCase()
		);

		switch (this.internalTitle.toLowerCase()) {
			case 'първа стъпка':
				this.icon = tvPurvaStupkaIcon;
				break;
			case 'ново начало':
				this.icon = tvNewBeginning;
				break;
			case 'филмов':
				this.icon = tvFilmov;
				break;
			case 'спортен':
				this.icon = tvSporten;
				break;
			case 'златен стандарт':
				this.icon = tvZlaten;
				break;
			case 'от а до я 300':
				this.icon = tvAdoQ;
				break;
			case 'от а до я 1000':
				this.icon = tvAdoQ;
				break;
			default:
				this.icon = tvPurvaStupkaIcon;
				break;
		}
	}

	getIncludedChannelImage(size) {
		switch (size) {
			case 'auroratv 210+':
				return [
					{
						icon: maxsport,
						height: 22
					},

					{
						icon: diema,
						height: 22
					}
				];
			case 'филмов екстра':
				return [
					{
						icon: hbo,
						height: 22
					},

					{
						icon: cinemax,
						height: 22
					},
					{
						icon: max,
						height: 22
					}
				];
			case 'спортен':
				return [
					{
						icon: maxsport,
						height: 22
					},

					{
						icon: diema,
						height: 22
					}
				];
			case 'златен стандарт':
				return [
					{
						icon: max,
						height: 19
					},
					{
						icon: hbo,
						height: 19
					},

					{
						icon: diema,
						height: 19
					},
					{
						icon: cinemax,
						height: 22
					}
				];
			case 'от а до я':
				return [
					{
						icon: max,
						height: 16
					},
					{
						icon: hbo,
						height: 16
					},

					{
						icon: maxsport,
						height: 16
					},
					{
						icon: cinemax,
						height: 16
					},
					{
						icon: diema,
						height: 16
					},
					{
						icon: sevenEight,
						height: 16
					}
				];
			default:
				return null;
		}
	}

	getPriceAccordingToDuration = () => {
		switch (parseInt(this.duration)) {
			case 0:
				return this.originalPrice * 1.5;
			case 1:
				return this.originalPrice * 1.2;
			default:
				return this.originalPrice;
		}
	};

	setTvCount = (tvCount) => (this.hdFetcher = tvCount);

	getPriceWithExtras = () => {
		return (
			this.getPriceAccordingToDuration() +
			this.getAdditionalTVsPrice(this.hdFetcher) +
			this.getExtrasPrice()
		);
	};

	getAdditionalTVsPrice = (numberOfTvs) => {
		const tvNum = parseInt(numberOfTvs);
		switch (tvNum) {
			case 1:
				return 0;
			case 2:
			case 3:
				return (tvNum - 1) * 2.5;
			default:
				return (tvNum - 3) * 5 + 5;
		}
	};

	toString = () => {
		return (
			'Пакет: ' +
			this.internalTitle +
			'\n<br>Канали: ' +
			this.title +
			'\n<br>Срок на договор: ' +
			this.duration +
			'г.' +
			'\n<br>Базова цена: ' +
			String(this.originalPrice).split('.')[0] +
			'.' +
			String(this.originalPrice)
				.split('.')[1]
				.slice(0, 2) +
			'\n<br>Брой ТВ приемници: ' +
			this.hdFetcher +
			'\n<br>Екстри: ' +
			this.getPriceMain() +
			'.' +
			this.getPriceCoins()
		);
	};
}

Object.setPrototypeOf(TvPackage.prototype, InternetPackage);

export default TvPackage;

